import { useEffect, useState } from "react";

const KeywordSlot = (props) => {

    const loadExample = props.loadExample;
    const [required, setRequired] = useState(true);
    const [keywordsText, setKeywordsText] = useState('');

    const toggleRequired = () => {
        setRequired((value) => !value);
    };

    const updateKeywordsText = (event) => {
        setKeywordsText(event.target.value);
    }

    const updatedStatus = () => {
        if (props.saveStatus == null) return;

        const keywords = keywordsText.split("\n").filter(s => s != '');

        if (!required) {
            keywords.push('');
        }
        props.saveStatus(keywords);
    }

    useEffect( () => {
        updatedStatus()
    }, [required, keywordsText]);

    useEffect( () => {
        if(loadExample) {
            setKeywordsText(props.example.join("\n"));
            setRequired(props.exampleRequired);
        }

    },[loadExample]);

    return (
        <div style={{...props.style, ...slotStyle.container}}>
            <div style={slotStyle.fullHeight}>
                <textarea style={slotStyle.fullHeight} onChange={updateKeywordsText} value={keywordsText}/>
            </div>
            <div onClick={toggleRequired}>
                <input readOnly={true} type="checkbox" checked={required}/><label>Required</label>
            </div>
        </div>
    )
}

const slotStyle = {
    fullHeight: {
        height: '90%',
        marginBottom: '10px',
        width: '100%'
    },

    container: {
        marginRight: '5%',
    }
}

export default KeywordSlot;