import { useEffect, useState } from 'react';
import KeywordSlot from './KeywordSlot';
import KeywordOutput from './KeywordOutput';

const KeywordAssembler = (props) => {
    const colCount = parseInt(props.colCount) ?? 3;
    const initial = [];
    for(let i = 0; i < colCount; i++) {
        initial.push([]);
    }
    const [keywordsCols, setKeywordsCols] = useState(props.keywordsCols ?? initial);
    const [keywords, setKeywords] = useState([]);
    const [loadExample, setLoadExample] = useState(false);

    const exampleCols = [
        [
            'best',
            'top rated',
            'simple',
            'fast',
            'easy',
            'free',
        ],
        [
            'keyword',
        ],
        [
            'builder',
            'generator',
            'mixer',
            'combiner',
        ],
        [
            'tool',
            'software',
            'site',
            'page',
            'app',
        ],
        [
            'English',
            'US',
            'Canada',
            'UK',
            'Australia',
        ],
    ];
    const exampleRequired = [false, true, true, false, false];

    const createKeywords = () => {
        let generatedKeywords = [];

        const sizes = keywordsCols.map(a => a.length);
        const indexes = new Array(sizes.length).fill(0);

        const moveIndex = () => {
            for (let i=indexes.length-1; i >= 0; i--) {
                indexes[i]++;
                if (indexes[i] >= sizes[i]) {
                    if (i != 0) {
                        indexes[i] = 0;
                    }
                } else {
                    break;
                }
            }
        }

        let keywordTotal = sizes.reduce((accum, n) => accum * (n==0?1:n), 1);
        while (keywordTotal-- > 0) {
            let word = "";
            for(let i=0; i<indexes.length; i++) {
                if (keywordsCols[i].length > indexes[i]) {
                    word = word + ' ' + keywordsCols[i][indexes[i]];
                }
            }
            const wordWithCleanSpacing = word.trim().replace(/\s+/g, ' ');
            if (wordWithCleanSpacing != '') {
                generatedKeywords.push(wordWithCleanSpacing);
            }
            moveIndex();
        } 
        
        setKeywords(generatedKeywords);
    }

    const saveColumn = (col) => (content) => {
        setKeywordsCols((current) => {
            let newCols = [...current];
            newCols[col] = content;
            return newCols;
        });
    }

    const exactMatchKeywords = () => {
        return keywords.map((s) => '[' + s + ']');
    }

    const phraseMatchKeywords = () => {
        return keywords.map((s) => '"' + s + '"');
    }

    const broadMatchKeywords = () => {
        return keywords.map((s) => '+' + s.replaceAll(' ', ' +'));
    }

    const enableExample = () => {
        setLoadExample(true);
    }

    useEffect(() => {
        createKeywords();
    }, [keywordsCols])

    useEffect(() => {
        setLoadExample(false);
    },[loadExample])

    return (
        <div>
            {keywords.length == 0 && 
            <button onClick={enableExample} style={assemblerStyles.exampleButton}>Load example</button>
            }
            <div style={assemblerStyles.input}>
                {keywordsCols.map((col, idx) => {
                    return (<KeywordSlot key={idx} example={exampleCols[idx]} exampleRequired={exampleRequired[idx]} loadExample={loadExample && keywords.length==0} saveStatus={saveColumn(idx)} style={assemblerStyles.column}/>)
                })}
            </div>
            {keywords.length > 0 && 
            <div style={assemblerStyles.resultsContainer}>
                <h2>Generated {keywords.length} keywords:</h2>
                <div style={assemblerStyles.outputContainer}>
                    <KeywordOutput style={assemblerStyles.outputItem} matchType='raw' keywords={keywords}/>
                    <KeywordOutput style={assemblerStyles.outputItem} matchType='exact match' keywords={exactMatchKeywords()}/>
                    <KeywordOutput style={assemblerStyles.outputItem} matchType='phrase match' keywords={phraseMatchKeywords()}/>
                    <KeywordOutput style={assemblerStyles.outputItem} matchType='broad match' keywords={broadMatchKeywords()}/>
                </div>
            </div>
            }
        </div>
    );
}

const assemblerStyles = {
    input: {
        display: 'flex',
        flexDirection: 'row',
        height: '170px',
        width: '100%'
    },
    column: {
        width: '20%',
    },
    resultsContainer: {
        display: 'block',
        width: '100%',
        marginTop: '40px'
    },
    exampleButton: {
        marginBottom: '5pt',
    },
    outputContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    outputItem: {
        margin: '0 50px 0 0'
    }
}

export default KeywordAssembler;