import KeywordAssembler from './components/KeywordAssembler';

const App = () => {

  return (
    <div style={appStyle.mainDiv}>
      <h1 style={appStyle.mainTitle}>Keyword Builder Tool</h1>
      <div style={appStyle.siteDescription}>
        <p>Type words or phrases in different boxes and this tool will create all possible combination with keywords from each box.</p>
        <p>Use line breaks to separate each keyword or phrase within the same box.</p>
        <p>Write keywords in the order of appeareance inside the phrase you want to create.</p>
        <p>Don't use prepositions or connectors. Only use words that add substantial meaning to the phrase.</p>
      </div>
      <KeywordAssembler colCount='5'/>
    </div>
  );
}

const appStyle = {
  mainDiv: {
    margin: '0 auto 40px auto',
    width: '70%'
  },

  mainTitle: {
    textAlign: 'center',
  }
}

export default App;
