
const KeywordOutput = (props) => {

    const keywords = props.keywords;
    const matchType = props.matchType;

    const keywordsAsText = (words) => {
        return words.reduce((accum, n) => {
            const word = accum + n + "\n";
            return word;
        }, "")
    }

    return (
        <div style={props.style}>
            <h3>{matchType} keywords</h3>
            <pre>
                <code style={outputStyles.results}>
                    {keywordsAsText(keywords.slice(0, 10))}
                    {keywords.length > 10 &&
                    <span>...</span>
                    }
                </code>
            </pre>
            <button onClick={() => navigator.clipboard.writeText(keywordsAsText(keywords))}>Copy all {keywords.length} {matchType} keywords</button>
        </div>
    )
}

const outputStyles = {
    results: {
        height: 'auto'
    }
}

export default KeywordOutput;